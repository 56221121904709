import React, { memo, useEffect, useState } from "react";
import { useSnackbar } from "notistack";

import { useDeviceLive, useDeviceProjectId, useDeviceStoreInfo, useDevicePlatform, DEVICE_PLATFORMS } from "../services/DeviceService";
import { useSystemAdmin } from "../services/UserService";
import { updateUrl } from "../services/UiService";
import { areDictsEqual } from "../utils/generalUtils";
import { useDeviceInProject, useProject } from "../services/ProjectService";
import { isLoading } from "../utils/uiUtils";

import PullUpPanel from "./PullUpPanel";
import DeviceGeneral from "./DeviceGeneral";
import DeviceNotes from "./DeviceNotes";
import DeviceMessages from "./DeviceMessages";
import DeviceTransfer from "./DeviceTransfer";
import DeviceCC from "./DeviceCC";
import DeviceRemote from "./DeviceRemote";
import StoreDetailsToolTip from "./StoreDetailsToolTip";

import { DeviceStrings } from "../strings";
import { getProjectDevicesRoute, getProjectDeviceTabRoute } from "../route";
import { useCallback } from "react";

// align with tabs below, and also DEVICE_LAST_EVENT_TYPES
const URLS = ["general", "note", "message", "transfer", "remote", "cc"];

// const config = {
//   onClose: () => {},
//   expand: true,
//   tab: "general",
// }
const DevicePanel = ({ deviceId, projectId, config, permissions }) => {
  const { enqueueSnackbar } = useSnackbar();
  const deviceLive = useDeviceLive(deviceId);
  const platform = useDevicePlatform(deviceId);
  const isQrId = platform === DEVICE_PLATFORMS.QRID;
  const valid = useDeviceInProject({ deviceId, projectId });
  const currentProjectId = useDeviceProjectId(deviceId);
  const isAdmin = useSystemAdmin();

  const sameProject = currentProjectId === projectId;
  const live = valid && deviceLive;
  const storeDetails = useDeviceStoreInfo(deviceId);
  const projectDetails = useProject(projectId);

  // default tab
  // config?.tab = url
  const [currentTab, setCurrentTab] = useState(
    Math.max(0, URLS.indexOf(config?.tab))
  );
  const forceOpen = !!config?.expand;

  // align with URLS above
  const tabs = [
    {
      label: DeviceStrings.TAB_GENERAL,
      render: (halfOpened, active) => (
        <DeviceGeneral
          deviceId={deviceId}
          projectId={projectId}
          halfOpened={halfOpened}
          permissions={permissions}
          platform={platform}
          live={live}
        />
      ),
    },
    {
      label: DeviceStrings.TAB_NOTES,
      render: (halfOpened, active) => (
        <DeviceNotes
          deviceId={deviceId}
          projectId={projectId}
          permissions={permissions}
          active={active}
        />
      ),
    },
    {
      label: DeviceStrings.TAB_MESSAGES,
      render: (halfOpened, active) => (
        <DeviceMessages
          deviceId={deviceId}
          projectId={projectId}
          permissions={permissions}
          active={active}
        />
      ),
    },
  ];
  if (live === true) {
    tabs.push({
      label: DeviceStrings.TAB_TRANSFER,
      render: (halfOpened, active, dismiss) => (
        <DeviceTransfer
          devices={{ [deviceId]: { deviceId, platform } }}
          projectId={projectId}
          permissions={permissions}
          active={active}
          dismiss={dismiss}
        />
      ),
    });
  }
  if (!isQrId) {
    tabs.push({
      label: DeviceStrings.TAB_REMOTE,
      render: (halfOpened, active, dismiss) => (
        <DeviceRemote
          deviceId={deviceId}
          projectId={projectId}
          halfOpened={halfOpened}
          permissions={permissions}
          active={active}
        />
      ),
    });
  }
  if (isAdmin && !isQrId && !projectDetails?.version) {
    tabs.push({
      label: DeviceStrings.TAB_CC,
      render: (halfOpened, active, dismiss) => (
        <DeviceCC
          deviceId={deviceId}
          projectId={projectId}
          halfOpened={halfOpened}
          permissions={permissions}
          active={active}
          dismiss={dismiss}
        />
      ),
    });
  }
  // show panel only if device id is given and
  const openPanel = valid && sameProject;

  const onClickTab = useCallback(
    (oldTab, newTab) => {
      setCurrentTab(newTab);
      updateUrl(getProjectDeviceTabRoute(projectId, deviceId, URLS[newTab]));
    },
    [projectId, deviceId]
  );

  // set url when open
  useEffect(() => {
    if (openPanel) {
      onClickTab(-1, currentTab);
    } else if (deviceId === null) {
      // no deviceId = closing panel, reset tab
      setCurrentTab(0);
    }
  }, [openPanel, currentTab, onClickTab, deviceId]);

  // check invalid
  useEffect(() => {
    if (!deviceId || isLoading(valid)) return;
    if (!valid) {
      enqueueSnackbar(DeviceStrings.INVALID_DEVICE_MSG, {
        variant: "error",
      });
      updateUrl(getProjectDevicesRoute(projectId));
    }
  }, [deviceId, valid, enqueueSnackbar, projectId]);

  // check transferred
  useEffect(() => {
    if (valid && !sameProject && !!currentProjectId) {
      enqueueSnackbar(DeviceStrings.TRANSFER_COMPLETED, {
        variant: "success",
      });
      updateUrl(getProjectDevicesRoute(projectId));
    }
  }, [valid, sameProject, currentProjectId, enqueueSnackbar, projectId]);

  const configPanel = {
    onClose: () => {
      if (config.onClose) config.onClose();
    },
    title: deviceId,
    canHalfOpen: !forceOpen,
    canExpandFull: true,
    canCopyTitle: true,
    tabs,
    currentTab,
    onClickTab,
    keepMounted: true,
    tooltipContent: <StoreDetailsToolTip storeDetails={storeDetails} />,
  };

  return <PullUpPanel open={openPanel} config={configPanel} />;
};

const isPanelEqual = (p1, p2) =>
  p1.deviceId === p2.deviceId &&
  p1.projectId === p2.projectId &&
  p1.config.expand === p2.config.expand &&
  p1.config.tab === p2.config.tab &&
  areDictsEqual(p1.permissions, p2.permissions);

export default memo(DevicePanel, isPanelEqual);
