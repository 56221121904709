import { DEVICE_PLATFORMS } from "services/DeviceService";
import { isOnline, getRelativeTimeString } from "../utils/localeUtils";
import { areDictsEqual } from "./generalUtils";

const CLOUD_CONTROL_URL = "https://cc.myplayer.io/device/imei/{id}";

export const DEVICE_DEPLOYMENT_STATE = {
  COMPLIANT: "compliant",
  NONCOMPLIANT: "noncompliant",
  ERROR: "error",
};

export const TIMESTAMP_ADJUSTMENT = 0;

// COLLECTIONS.DEVICE
export const DEVICE_INFO_KEYS = [
  "deviceId",
  "storeId",
  "postcode",
  "retailerName",
  "country",
  "sim",
  "decommissioned",
  "note",
];
// COLLECTIONS.DEVICE_STATUS
export const DEVICE_STATUS_KEYS = [
  "updatedAt", // last online
  "stage", // last message received
  // "onComplianceCheckStart", // last compliance checked
];
// COLLECTIONS.DEVICE_DEPLOYMENT
export const DEVICE_DEPLOYMENT_KEYS = [
  "state", // compliance state
  "moment", // last deployed => last compliance checked
  "projectId",
];

export const extractDeviceData = (data, keys) =>
  Object.fromEntries(Object.entries(data).filter(([k, v]) => keys.includes(k)));

export const isDeviceOnline = (info) => {
  console.warn("[Deprecated] isDeviceOnline is deprecated, use DeviceService.useDeviceOnline instead");
  if (!info) return undefined;
  if (!info?.updatedAt) return null;
  if (!info.platform) info.platform = DEVICE_PLATFORMS.ANDROID;
  return isOnline(new Date(info.updatedAt), info.platform);
};

export const lastOnlineString = (info) => {
  if (!info) return undefined;
  if (!info?.updatedAt) return null;
  return getRelativeTimeString(new Date(info.updatedAt));
};

export const getDevicePlatform = (info) => {
  if (!info) return undefined;
  if (!info?.platform) return null;
  return info.platform;
};

export const isDeviceDecommissioned = (info) => info && !!info.decommissioned;

export const getDeviceLastUpdatedTimestamp = (info) =>
  info && info.updatedAt && Date.parse(info.updatedAt) + TIMESTAMP_ADJUSTMENT;

export const getDeviceLastComplianceCheckTimestamp = (info) =>
  info && info.moment && Date.parse(info.moment) + TIMESTAMP_ADJUSTMENT;

export const openCloudControl = (deviceId) => {
  window.open(CLOUD_CONTROL_URL.replace("{id}", deviceId), "_blank");
};

export const sortProjectProgres = (progress) => {
  return Object.entries(progress)
    .filter(([k, v]) => !!v.moment)
    .sort(([k1, v1], [k2, v2]) => Date.parse(v2.moment) - Date.parse(v1.moment))
    .map(([k, v]) => ({ ...v, stage: k }));
};

export const areProgressesEqual = (obj1, obj2) =>
  areDictsEqual(obj1, obj2, (a, b) => a.moment === b.moment);
