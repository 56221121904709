import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
  useRef,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import ViewCarouselOutlinedIcon from "@material-ui/icons/ViewCarouselOutlined";
import SearchIcon from "@material-ui/icons/Search";

import { useMobileLayout } from "hooks/uiHooks";
import { isLoading } from "utils/uiUtils";
import { getProjects } from "services/ApiService";
import ProjectCard from "./cards/projectCard/ProjectCard";
import Spinner from "./Spinner";
import InfiniteScroll from "./InfiniteScroll";
import { EmptyData } from "./emptyData";
import {
  getCurrentUserId,
  useProjectUsersLastUpdate,
} from "services/UserService";
import { useUserOrganisationPermissions } from "services/OrganisationService";
import { ProjectStrings } from "strings";
import MainContext from "context/MainContext";
import {
  PROJECT_SORT_OPTION_FIELD,
  PROJECT_DESC_SORT_OPTIONS,
} from "ui/pageLayout/config";

const LIMIT_DEFAULT = 20;

const useStyles = makeStyles((theme) => {
  return {
    cardContainer: ({ mobile, small }) => ({
      padding: theme.spacing(1),
      paddingLeft: mobile ? theme.spacing(2) : theme.spacing(1),
      paddingRight: mobile ? theme.spacing(2) : theme.spacing(1),
      width: mobile ? window.innerWidth : small ? 240 : 320,
      height: small ? 240 : 360,
      boxSizing: "border-box",
    }),
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      overflowX: "hidden",
    },
    main: {
      padding: theme.spacing(2),
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      justifyContent: "center",
    },
  };
});

const ProjectList = (props) => {
  const { config, ...restParams } = props;
  const {
    organisationId,
    exclude,
    small = false,
    showOrgProjects,
    userRoles,
    onClick,
    clickToOpen,
    onAdd,
  } = config;
  const mobile = useMobileLayout();
  const classes = useStyles({ mobile, small });
  const {
    sortFieldValue,
    sortDescValue,
    searchValue: searchValueContext,
  } = useContext(MainContext);
  const mountedRef = useRef(true);
  const [isFetchedAll, setIsFetchedAll] = useState(false);
  const [projectsIds, setProjectsIds] = useState();
  const [fetchedAt, setFetchedAt] = useState();
  const [startAfter, setStartAfter] = useState();
  const [hoverProjectId, setHoverProjectId] = useState();
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const searchValue =
    typeof restParams.searchValue !== "undefined"
      ? restParams.searchValue
      : searchValueContext;

  const userId = getCurrentUserId();
  const { canWriteProjects } = useUserOrganisationPermissions({
    userId,
    organisationId,
  });
  const projectUsersLastUpdate = useProjectUsersLastUpdate();

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const emptyData = useMemo(() => {
    if (projectsIds && projectsIds.length) return {};
    const dataText = {};

    if (loading) return dataText;
    // text for empty search
    if (projectsIds?.length === 0 && searchValue) {
      dataText.title = ProjectStrings.PROJECTS_NO_FOR_SEARCH;
      dataText.icon = <SearchIcon />;
      return dataText;
    }

    // text for no projects in the organisation
    if (showOrgProjects && typeof startAfter === "undefined") {
      dataText.title = ProjectStrings.PROJECTS_NO_IN_ORGANISATION;
      dataText.description = ProjectStrings.PROJECTS_NO_IN_ORGANISATION_DESC;
      dataText.actionTitle = onAdd && ProjectStrings.PROJECTS_ADD_FIRST;
      dataText.icon = <ViewCarouselOutlinedIcon />;
      return dataText;
    }

    // text for no projects user in
    if (!showOrgProjects && typeof startAfter === "undefined") {
      dataText.title = ProjectStrings.PROJECTS_NO_PROJECTS;
      dataText.description = ProjectStrings.PROJECTS_NO_MY_PROJECTS_DESC;
      dataText.actionTitle = onAdd && ProjectStrings.PROJECTS_ADD_FIRST;
      dataText.icon = <ViewCarouselOutlinedIcon />;
      return dataText;
    }

    return dataText;
  }, [loading, onAdd, projectsIds, searchValue, showOrgProjects, startAfter]);

  const resetData = () => {
    setIsFetchedAll(false);
    setStartAfter();
    setIsRequestSent(true);
  };

  const getParams = useCallback(
    (params) => {
      const { sortDesc, sortValue, lastKey } = params;
      const orderBy =
        sortValue && sortValue in PROJECT_SORT_OPTION_FIELD
          ? PROJECT_SORT_OPTION_FIELD[sortValue]
          : PROJECT_DESC_SORT_OPTIONS[PROJECT_DESC_SORT_OPTIONS[0]];

      const paramsForRequest = {
        userRoles,
        organisationId,
        lastKey,
        limit: LIMIT_DEFAULT,
        orderBy,
        orderDesc:
          sortValue in sortDesc
            ? sortDesc[sortValue]
            : PROJECT_DESC_SORT_OPTIONS[sortValue],
        searchValue: params.searchValue,
        organisationProjectsOnly: !!showOrgProjects,
      };

      return paramsForRequest;
    },
    [organisationId, showOrgProjects, userRoles]
  );

  const fetchData = useCallback(
    async (params, isLoadingMore = false) => {
      const requestParams = getParams({
        sortDesc: sortDescValue,
        sortValue: sortFieldValue,
        searchValue,
        ...params,
      });

      if (!isLoadingMore) {
        setLoading(true);
      }

      try {
        let responseData;

        const res = await getProjects({
          ...requestParams,
        });
        if (!mountedRef.current) return null;

        responseData = res.result.items;
        setFetchedAt(res.result.fetchedAt);

        if (responseData.length === 0 || responseData.length < LIMIT_DEFAULT) {
          setIsFetchedAll(true);
          setIsRequestSent(false);
        }

        if (params?.lastKey && !isRequestSent) {
          setProjectsIds([...projectsIds, ...responseData]);
        } else {
          setProjectsIds(responseData);
          setIsRequestSent(false);
        }

        if (responseData.length) {
          setStartAfter(responseData[responseData.length - 1]);
        }
        if (!isLoadingMore) {
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        setLoadingMore(false);
        console.log(err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getParams, restParams, isRequestSent, projectsIds]
  );

  useEffect(() => {
    if (organisationId || organisationId === null) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId, sortFieldValue, sortDescValue, searchValue]);

  useEffect(() => {
    // do request if project users last update date changed
    if (projectUsersLastUpdate > fetchedAt) {
      resetData();
      fetchData({ lastKey: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectUsersLastUpdate, fetchedAt]);

  const configCard = {
    showBrand: true,
    showParticipants: !small,
    useUnread: true,
    showOrgName: !showOrgProjects && !organisationId,
    showPin: !showOrgProjects,
    onPinProject: () => {
      resetData();
      fetchData();
    },
  };

  const configScroll = {
    onLoadMore: async () => {
      if (isFetchedAll || !startAfter || loading) return;

      setLoadingMore(true);
      await fetchData({ lastKey: startAfter });
      setLoadingMore(false);
    },
  };

  const onAddProjectHandle = () => {
    if (onAdd) onAdd();
  };

  const renderContent = (() => {
    if (isLoading(projectsIds) || (loading && !loadingMore)) return <Spinner />;

    if (!projectsIds.length)
      return (
        <EmptyData
          title={emptyData.title}
          description={emptyData.description}
          actionTitle={emptyData.actionTitle}
          icon={emptyData.icon}
          onClick={canWriteProjects && onAddProjectHandle}
        />
      );

    return (
      <InfiniteScroll config={configScroll} size={projectsIds.length}>
        <div className={classes.main}>
          {projectsIds
            .filter(
              (projectId) =>
                projectId && (!exclude || !exclude.includes(projectId))
            )
            .map((projectId) => (
              <div
                key={projectId}
                className={classes.cardContainer}
                onMouseOver={() => {
                  setHoverProjectId(projectId);
                }}
              >
                <ProjectCard
                  userId={userId}
                  projectId={projectId}
                  config={{
                    ...configCard,
                    hoverProjectId,
                    onClick: (projectId, joined) => onClick(projectId, joined),
                    clickToOpen,
                  }}
                />
              </div>
            ))}
        </div>
      </InfiniteScroll>
    );
  })();

  return (
    <div className={classes.root}>
      {renderContent}
      {loadingMore && projectsIds?.length !== 0 && (
        <div style={{ position: "relative", width: "100%", height: "80px" }}>
          <Spinner size={30} />
        </div>
      )}
    </div>
  );
};

export default ProjectList;
