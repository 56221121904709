import React from "react";
import {makeStyles} from "@material-ui/core/styles";

import {useDeviceCommandResponse} from "../../services/DeviceService";

const useStyles = makeStyles((theme) => ({
  logContainer: {
    boxShadow: "inset 0px 0px 10px rgba(0, 0, 0, 0.5)",
    overflow: "auto",
    width: "100%",
    height: "100%",
    maxHeight: 300,
  },
  logText: {
    whiteSpace: "pre",
    fontSize: 11,
    fontFamily: "monospace",
  },
}));

const RemoteLog = ({deviceId}) => {
  const classes = useStyles();

  const response = useDeviceCommandResponse(deviceId);

  return (
    <div className={classes.logContainer}>
      <span className={classes.logText}>
        {response?.error
          ? `ERROR: ${response.error.replaceAll("\\n", "\n")}`
          : response?.result.replaceAll("\\n", "\n")}
      </span>
    </div>
  );
};

export default RemoteLog;
