export const ACCOUNTS_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3010"
    : process.env.REACT_APP_DEMO_MODE
    ? "https://accounts-test.myplayer.io"
    : "https://accounts.myplayer.io";

export const STORY_SHARE_URL = process.env.REACT_APP_DEMO_MODE
  ? "https://share-test.myplayer.io"
  : "https://share.myplayer.io";

// uncomment if it needs to be server from localhost
// export const CUSTOMISE_QR_URL = "http://localhost:5503";
export const CUSTOMISE_QR_URL = process.env.REACT_APP_DEMO_MODE
  ? "https://qr-myplayer-test.web.app"
  : "https://qr-myplayer.web.app";
