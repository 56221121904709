import { useEffect, useState } from "react";
import { Firebase } from "../App";

import {
  isUserInProject,
  getProjectDeviceIds,
  getProjectInfo,
} from "../services/ProjectService";
import {
  isUserInOrganisation,
  getUserInOrganisation,
} from "./OrganisationService";
import {
  setUserInfo,
  isSystemAdmin,
  getCurrentOrganisation,
} from "../services/UserService";
import { getCookieByKey } from "../utils/generalUtils";
import { ACCOUNTS_URL } from "../configs/urlConfig";
import { ORGANISATION_PERMISSIONS } from "utils/permissionUtils";

export const MODES = {
  VERIFY_EMAIL: "verifyEmail",
  RESET_PASSWORD: "resetPassword",
};

export const useAnonLogin = () => {
  const [result, setResult] = useState(false);
  useEffect(() => {
    if (Firebase.auth().currentUser) {
      setResult(true);
    } else {
      Firebase.auth()
        .signInAnonymously()
        .then(() => {
          setResult(true);
        })
        .catch((error) => {
          console.warn(error);
          setResult(false);
        });
    }
  }, []);
  return result;
};

// verifies password reset code
// returns a Promise with the user's email
// OBSOLETE
export const verifyPasswordResetCode = (oobCode) => {
  console.warn(
    "This function is obsolete, reset password is handled by accounts"
  );
  return Firebase.auth().verifyPasswordResetCode(oobCode);
};

// OBSOLETE
export const verifyCode = (code) => {
  console.warn(
    "This function is obsolete, reset password is handled by accounts"
  );
  return Firebase.auth().applyActionCode(code);
};

// logs user in with email and password
// returns a Promise
// OBSOLETE
export const login = (email, password) => {
  console.warn(
    "This function is obsolete, use sessionLogin() with redirectToLogin()"
  );
  return Firebase.auth().signInWithEmailAndPassword(email, password);
};

// creates a new user, updates the user collection with document format found in rrfconfig and sends email verification
// returns a Promise
// OBSOLETE
export const register = async (email, password, fullname) => {
  console.warn("This function is obsolete, register is handled by accounts");
  await Firebase.auth().createUserWithEmailAndPassword(email, password);
  const currentUser = Firebase.auth().currentUser;
  await setUserInfo(email, fullname, currentUser.uid);
  return currentUser.sendEmailVerification();
};

// sends a password reset email
// returns a Promise
// OBSOLETE
export const resetPassword = (email) => {
  console.warn(
    "This function is obsolete, reset password is handled by accounts"
  );
  return Firebase.auth().sendPasswordResetEmail(email);
};

// resets user's password
// return Promise
// OBSOLETE
export const confirmPasswordReset = (oobCode, password) => {
  console.warn(
    "This function is obsolete, reset password is handled by accounts"
  );
  return Firebase.auth().confirmPasswordReset(oobCode, password);
};

// Verify password reset code and get the email
// return email as String
// undefined = loading
// null = invalid
// String = valid email
export const usePasswordResetEmail = ({ mode, oobCode }) => {
  const [email, setEmail] = useState();

  useEffect(() => {
    if (mode !== MODES.RESET_PASSWORD && !oobCode) return;

    verifyPasswordResetCode(oobCode)
      .then((email) => {
        setEmail(email);
      })
      .catch((error) => {
        console.warn(error);
        setEmail(null);
      });
  }, [mode, oobCode]);

  return email;
};

// Verify account
// return result as boolean
// undefined = loading
// false = invalid
// true = valid
export const useVerifyCode = ({ mode, oobCode }) => {
  const [result, setResult] = useState();

  useEffect(() => {
    if (mode !== MODES.VERIFY_EMAIL && !oobCode) return;

    verifyCode(oobCode)
      .then(() => {
        setResult(true);
      })
      .catch((error) => {
        console.warn(error);
        setResult(false);
      });
  }, [mode, oobCode]);

  return result;
};

/**
 * sso
 */

const AUTH_BASE = "/accountsApi";

const getLoginUrl = (path) =>
  `${ACCOUNTS_URL}/login?continue=${path ? window.location.origin + path : window.location.href
  }`;

const getSignupUrl = (path) =>
  `${ACCOUNTS_URL}/signup?continue=${path ? window.location.origin + path : window.location.href
  }`;

const getAccountsUrl = () =>
  `${ACCOUNTS_URL}/?continue=${window.location.href}`;

const getXssToken = async () => {
  const xssToken = getCookieByKey("xss");
  if (!xssToken) {
    await sessionLogout();
  }
  return xssToken;
};

const getCustomToken = async () => {
  const xssToken = await getXssToken();
  if (!xssToken) throw new Error(`No xssToken`);
  const res = await fetch(`${AUTH_BASE}/checkAuthStatus`, {
    method: "POST",
    headers: { Authorization: `Bearer ${xssToken}` },
  });
  if (!res.ok) throw new Error(`Error ${res.status}: ${res.statusText}`);
  const json = await res.json();
  console.debug("getCustomToken", json);
  if (!json.status || !json.customToken)
    throw new Error(`Error: Failed to obtain custom token`);
  return json.customToken;
};

const deleteCookie = (name) => {
  document.cookie = `${name}=; expires=${new Date().toUTCString()}; path=/;`;
};

export const sessionLogout = async () => {
  await Promise.all([
    fetch(`${AUTH_BASE}/sessionLogout`, {
      method: "POST",
    }),
    Firebase.auth().signOut(),
  ]);
  if (
    process.env.NODE_ENV === "development" && // if development
    !process.env.REACT_APP_LOCAL_API // and not using LOCAL_API
  ) {
    // delete xss token because sessionLogout won't be able to clear localhost cookie
    deleteCookie("xss");
  }
};

export const sessionLogin = async () => {
  const token = await getCustomToken();
  if (!token) throw new Error("No xssToken");
  console.debug("Logging in with custom token...");
  await Firebase.auth().signInWithCustomToken(token);
};

// check current auth status from token and make sure logging in with right user
export const checkAuthStatus = async () => {
  const token = await getCustomToken();
  const user = await Firebase.auth().signInWithCustomToken(token);
  console.debug("token user", user?.user, user?.user?.email);
};

export const redirectToLogin = (path) => {
  window.location.href = getLoginUrl(path);
};

export const redirectToSignup = (path) => {
  window.location.href = getSignupUrl(path);
};

export const redirectToAccounts = () => {
  window.location.href = getAccountsUrl();
};

export const validateSession = async () => !!(await getXssToken());

/**
 * auth check functions to check user's permission in App.js, to be used by AuthRoute
 */

export const userExist = async (user) => {
  await validateSession();
  return !!user;
};

export const userNotExist = async (user) => {
  await validateSession();
  return !user;
};

export const userAndDevicesExistInProject = async (userId, projectId) => {
  await validateSession();
  let valid = false;
  const userExistsInProject = await isUserInProject({ userId, projectId });
  if (userExistsInProject) {
    // need to make sure user exists in the project first otherwise would cause a Firebase permission error
    const devicesIds = await getProjectDeviceIds(projectId);
    if (devicesIds.length > 0) {
      valid = true;
    }
  }
  return valid;
};

export const userIsAdmin = async (user) => {
  await validateSession();
  return await isSystemAdmin();
};

export const userExistAndInProject = async (userId, projectId) => {
  await validateSession();
  const isAdmin = await userIsAdmin(); // check is user is SuperAdmin

  if (isAdmin) {
    return true;
  }

  const info = await getProjectInfo(projectId);

  if (info === null) return false;

  const selectedOrgId = await getCurrentOrganisation();

  const userInOrg = await getUserInOrganisation({
    userId,
    organisationId: selectedOrgId,
  });

  if (userInOrg?.permissionsKey === ORGANISATION_PERMISSIONS.ADMIN) return true;

  return isUserInProject({ userId, projectId });
};

export const userExistAndInOrganisation = async (userId, organisationId) => {
  await validateSession();

  const isAdmin = await userIsAdmin();

  if (isAdmin) {
    return userIsAdmin;
  }

  return isUserInOrganisation({ userId, organisationId });
};

export const alwaysAllow = () => true;
