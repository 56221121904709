import React, { useState } from "react";
import { Chip, TextField } from "@material-ui/core";

import {
  useDefaultCommands,
  useDefaultApps,
  useDefaultCommandVariants,
  useDefaultCommand,
} from "../../services/TaskService";

import ButtonCard from "./ButtonCard";
import MySelect from "../MySelect";

import { ProjectStrings } from "../../strings";

const ApplicationSelect = ({ value, onChange, commandId }) => {
  //   const projectApps = useProjectAppsAll(canRead && projectId);
  const cmd = useDefaultCommand(commandId);
  const apps = useDefaultApps();

  return (
    <MySelect
      title="Application"
      value={cmd?.application ?? value}
      onChange={onChange}
      options={apps}
      disabled={Boolean(cmd?.application)}
    />
  );
};

const CustomInput = ({ input, values, onChange }) => {
  if (!input) return <></>;

  const onChangeInternal = (event, key) => {
    if (onChange) onChange(key, event.target.value);
  };

  return input
    .filter((i) => i.target !== "APPLICATION")
    .map(({ key, target }, i) => {
      switch (target) {
        //   case "APPLICATION":
        //   return (
        //     <ApplicationSelect
        //       key={key}
        //       value={values?.[key] || ""}
        //       onChange={(e) => onChangeInternal(e, key)}
        //     />
        //   );
        case "TEXT":
          return (
            <TextField
              key={`custom-input-${i}`}
              style={{ margin: 5 }}
              label={key}
              value={values?.[key] ?? ""}
              fullWidth
              onChange={(e) => onChangeInternal(e, key)}
            />
          );
        default:
          return <div key={`custom-input-${i}`}></div>;
      }
    });
};

const TaskVariants = ({ id }) => {
  const variants = useDefaultCommandVariants(id);
  if (!variants) return <></>;
  return (
    <div style={{ margin: 8, display: "flex", flexDirection: "column" }}>
      <span>Supported Platforms</span>
      <div style={{ display: "flex", gap: 8 }}>
        {Object.entries(variants).map(([k, v]) => (
          <Chip
            // style={{ margin: 8 }}
            key={`task-variant-${k}`}
            label={`${v?.alias}`}
          />
        ))}
      </div>
    </div>
  );
};

const AddTasksCard = ({
  title,
  projectId,
  description,
  canRead,
  onAddTask,
}) => {
  const commands = useDefaultCommands();

  const [category, setCategory] = useState("");
  const [commandId, setCommandId] = useState("");
  const [targetApplication, setTargetApplication] = useState("");
  const [input, setInput] = useState({});
  const [loading, setLoading] = useState(false);

  const commandTypes =
    commands &&
    Object.fromEntries(
      Object.values(commands).map((c) => [
        c.category,
        c.category.charAt(0) + c.category.slice(1).toLowerCase(),
      ])
    );
  const selectedCommands =
    commandTypes &&
    category &&
    Object.fromEntries(
      Object.entries(commands).filter(
        ([k, v]) => v.category.toLowerCase() === category.toLowerCase()
      )
    );

  const onAddTaskInternal = () => {
    setLoading(true);
    onAddTask({ projectId, commandId, targetApplication, input }).then(
      (res) => {
        console.debug("onAddTask", res);
      }
    ).finally(() => {
      setLoading(false);
    });
  };

  const onChangeCategory = (event) => {
    setCategory(event.target.value);

    setCommandId("");
    setInput({});
  };

  const onChangeName = (event) => {
    const id = event.target.value;
    setCommandId(id);

    setInput({});
  };
  const onChangeCustomInput = (key, value) => {
    setInput((p) => ({
      ...p,
      [key]: value,
    }));
  };

  const configCard = {
    icon: <span className="material-symbols-outlined">assignment_add</span>,
    title,
    desc: description,
    buttonLabel: ProjectStrings.TASKS_ADD_TASK_BUTTON,
    onClick: onAddTaskInternal,
    fullHeight: true,
    // disableButton: !commandInput,
    progress: loading,
  };

  return (
    <ButtonCard config={configCard} canRead={canRead}>
      {commandTypes && (
        <MySelect
          title="Category"
          value={category}
          onChange={onChangeCategory}
          options={commandTypes}
        />
      )}

      {category === "APPLICATION" && (
        <ApplicationSelect
          key={"command-application"}
          value={targetApplication}
          onChange={(e) => setTargetApplication(e.target.value)}
          commandId={commandId}
        />
      )}

      {selectedCommands && (
        <>
          <MySelect
            title="Task Name"
            value={commandId}
            onChange={onChangeName}
            options={selectedCommands}
          />
        </>
      )}

      {commandId && (
        <div style={{ padding: 8, boxSizing: "border-box" }}>
          Description: {commands[commandId].desc}
        </div>
      )}

      {commands?.[commandId]?.input && (
        <CustomInput
          input={commands[commandId].input}
          values={input}
          onChange={onChangeCustomInput}
        />
      )}

      {commandId && <TaskVariants id={commandId} />}
    </ButtonCard>
  );
};

export default AddTasksCard;
